import { useContext, useEffect, useState } from "react";
import { DailyCall, DailyCallOptions } from "@daily-co/daily-js";
import { captureException, startSpan } from "@sentry/react";
import { omit } from "lodash";
import { DateTime } from "luxon";
import { runInAction } from "mobx";
import { StoreContext, loggerContext } from "@/stores";
import { DailyError, DailyUserType, encodeUserName } from "@/utils/daily.utils";
import { initLogger } from "@/utils/logging.utils";

export type ConnectionTargetResult = {
  startedAt: DateTime;
  finishedAt?: DateTime;
  errorMessage?: string;
  warningMessages?: string[];
};

export type ConnectionResult<T extends string> = {
  startedAt: DateTime;
  targets: Partial<Record<T, ConnectionTargetResult>>;
  finishedAt?: DateTime;
};

export type DailyConnectionStep = "prepare" | "join";

export type DailyConnectionError = { message: string; failedStep?: DailyConnectionStep };

class HandledDailyConnectionError extends Error {}

export type DailyConnectionTarget = "parallel" | "streaming" | "whiteboard" | "chat";

export type DailyConnectionResult = ConnectionResult<DailyConnectionTarget>;

type DailyConnectionTargetResults = Partial<Record<DailyConnectionTarget, ConnectionTargetResult>>;

export type DailyMeetingConnectionParams = {
  dailyCall: DailyCall;
  meetingKey: string;
  participantKey: string;
  user: { id?: string; name?: string; type: DailyUserType };
  isWaiting?: boolean;
};

const logger = initLogger("use-meeting-connection", loggerContext);

export const useDailyMeetingConnection = (params: DailyMeetingConnectionParams) => {
  const { dailyCall, meetingKey, participantKey, isWaiting, user } = params;
  const { id: userId, name: userName = `User ${user.id || "X"}`, type: userType } = user;
  const {
    apiStore: { meetingApi },
    activityStore,
    alertStore,
    assessmentStore,
    chatStore,
    meetingStore,
    participantStore,
    userStore,
    whiteboardStore,
  } = useContext(StoreContext);
  const { isStaff: isStaff } = userStore;

  const [isFinished, setIsFinished] = useState(false);
  const [error, setError] = useState<DailyConnectionError>();

  const targetResults: DailyConnectionTargetResults = {};
  const [result, setResult] = useState<DailyConnectionResult>({ startedAt: DateTime.utc(), targets: targetResults });

  const setTarget = (target: DailyConnectionTarget, newResult: Partial<ConnectionTargetResult>) => {
    targetResults[target] = { ...targetResults[target], ...newResult } as ConnectionTargetResult;
    setResult({ ...result, targets: targetResults });
  };

  const loggerParams = {
    ...omit(params, "dailyCall", "user"),
    userId,
    userName,
    userType,
  };
  const connect = async (isReconnect: boolean) =>
    startSpan({ name: "daily-meeting-connect", attributes: loggerParams }, async () => {
      const startedAt = DateTime.utc();
      logger.info("connecting to daily meeting room", { params: loggerParams });
      setIsFinished(false);
      setError(undefined);

      setTarget("parallel", { startedAt: DateTime.utc() });
      const prepareResponse = await meetingApi
        .prepareMeeting(meetingKey, {
          participantKey,
          userId,
          displayName: userName,
          showAssessments: true,
          isProvider: isStaff,
          isWaiting,
        })
        .catch(e => {
          const message = "failed to prepare parallel meeting";
          logger.postEvent("Error", message, { params: loggerParams, causedBy: e.message });
          setError({ message, failedStep: "prepare" });
          setTarget("parallel", { errorMessage: message });
          throw new HandledDailyConnectionError(message);
        })
        .finally(() => setTarget("parallel", { finishedAt: DateTime.utc() }));

      const { dailyRoom, games, assessmentStimulus, state, whiteboardRoom, streamChatToken } = prepareResponse;
      logger.postEvent("Connection", "prepared parallel meeting", { params: loggerParams, state, dailyRoom });

      if (!dailyRoom) {
        const message = "parallel server did not return a room";
        logger.postEvent("Error", message, { params: loggerParams });
        setError({ message, failedStep: "prepare" });
        setTarget("parallel", { errorMessage: message });
        throw new HandledDailyConnectionError(message);
      }

      const localParticipant = state.participants[participantKey];
      const dailyJoinParams: DailyCallOptions = {
        url: dailyRoom.url,
        userName: encodeUserName({ userName, userId }),
        userData: { participantKey, userType, displayName: userName },
        startVideoOff: localParticipant?.isVideoHidden,
        startAudioOff: localParticipant?.isAudioMuted,
      };
      // daily does not like `token: undefined` in the join params
      if (dailyRoom.token) dailyJoinParams.token = dailyRoom.token;

      setTarget("streaming", { startedAt: DateTime.utc() });
      const dailyParticipants = await dailyCall
        .join(dailyJoinParams)
        .catch(e => {
          const message = "failed to join daily room";
          const context = {
            params: loggerParams,
            dailyRoom,
            dailyJoinParams,
            causedBy: e,
          };
          logger.postEvent("Error", message, context, "VideoStreaming");
          captureException(new DailyError(e, "join"), { contexts: { event: context } });
          setError({ message, failedStep: "join" });
          setTarget("streaming", { errorMessage: message });
          throw new HandledDailyConnectionError(message);
        })
        .finally(() => setTarget("streaming", { finishedAt: DateTime.utc() }));

      logger.postEvent(
        "Connection",
        "joined daily room",
        { params: loggerParams, dailyRoom, dailyJoinParams, dailyParticipants },
        "VideoStreaming",
      );

      runInAction(() => {
        meetingStore.connectedMeetingKey = meetingKey;
        meetingStore.connectedParticipantKey = participantKey;
        meetingStore.displayState = state.display || undefined;
        meetingStore.appointmentFlags = prepareResponse.appointmentFlags;
        meetingStore.connectedDailyRoom = dailyRoom;
        participantStore.participants = state.participants;
        participantStore.localParticipantKey = participantKey;
        activityStore.currActivity = state.activity || undefined;
        activityStore.gameMetadata = games;
        assessmentStore.metadata = assessmentStimulus;
      });

      if (!isReconnect) {
        setTarget("whiteboard", { startedAt: DateTime.utc() });
        await whiteboardStore
          .connectToRoom({ whiteboardRoom, participantKey })
          .then(() =>
            logger.postEvent(
              "Connection",
              "connected to whiteboard",
              { params: loggerParams, whiteboardRoom },
              "Whiteboard",
            ),
          )
          .catch(e => {
            const message = "whiteboard service connection failed";
            logger.postEvent(
              "Warning",
              message,
              {
                params: loggerParams,
                whiteboardRoom,
                causedBy: e.message,
              },
              "Whiteboard",
            );
            setTarget("whiteboard", { warningMessages: [message] });
            runInAction(
              () => (whiteboardStore.errorMessage = "cannot connect to whiteboard due to an unexpected error"),
            );
            alertStore.push(`Unable to connect to whiteboard service`, {
              severity: "warn",
              details: "If this feature is needed for the session, please try refreshing the page",
            });
          })
          .finally(() => setTarget("whiteboard", { finishedAt: DateTime.utc() }));

        if (streamChatToken && userId) {
          setTarget("chat", { startedAt: DateTime.utc() });
          await chatStore
            .connectWithToken({
              streamChatToken,
              meetingKey,
              userId,
              userName,
            })
            .then(() =>
              logger.postEvent(
                "Connection",
                "connected to chat",
                { params: loggerParams, streamChatToken },
                "TextChat",
              ),
            )
            .catch(e => {
              const message = "chat service connection failed";
              logger.postEvent(
                "Warning",
                message,
                {
                  params: loggerParams,
                  streamChatToken,
                  causedBy: e.message,
                },
                "TextChat",
              );
              setTarget("chat", { warningMessages: [message] });
              runInAction(() => (chatStore.errorMessage = "cannot connect to chat due to an expected error"));
              alertStore.push(`Unable to connect to text chat service`, {
                severity: "warn",
                details: "If this feature is needed for the session, please try refreshing the page",
              });
            })
            .finally(() => setTarget("chat", { finishedAt: DateTime.utc() }));
        }
      }
      logger.postEvent("Connection", "full parallel meeting connection successful", {
        params: loggerParams,
        latencyMs: DateTime.utc().diff(startedAt).milliseconds,
      });
    })
      .catch(e => {
        if (e instanceof HandledDailyConnectionError) {
          logger.warn("handled connection error", { params }, e);
        } else {
          const message = "unhandled connection error";
          logger.postEvent("Error", message, { params: loggerParams, causedBy: e.message });
          setError({ message });
        }
      })
      .finally(() => setIsFinished(true));

  // managing this flag this to prevent React Strict Mode from double-calling this (see https://react.dev/learn/synchronizing-with-effects#fetching-data)
  let isFirstRun = true;
  useEffect(() => {
    if (!isFirstRun) {
      logger.info("skipping second run");
      return;
    }
    let isReconnect = false;
    let leavePromise = Promise.resolve();
    if (meetingStore.connectedDailyRoom) {
      if (isWaiting) {
        logger.info("already connected - will reconnect");
        isReconnect = true;
        leavePromise = dailyCall.leave();
      } else {
        logger.info("already connected - nothing to do");
        setIsFinished(true);
        return;
      }
    }
    leavePromise.then(() => connect(isReconnect));
    return () => {
      isFirstRun = false;
    };
  }, []);

  return {
    isFinished,
    error,
    result,
    reconnect: () => connect(true),
    userName,
  };
};
